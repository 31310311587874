<template>
  <div class="page-content">
            <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">Сайты</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item"></li>
                            <li class="breadcrumb-item active">Сайты</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                      <button @click="saveOffer()" :disabled="disabled" class="btn btn-secondary btn-sm btn-block">Сохранить</button>
                      <br>
                      <div class="input-group">
                        <button @click="editOfferData.active = 0" :class="editOfferData.active < 1 ? 'btn-primary' : 'btn-outline-primary'" class="btn btn-sm mb-0" type="button">Отключен</button>
                        <button @click="editOfferData.active = 1" :class="editOfferData.active > 0 ? 'btn-primary' : 'btn-outline-primary'" class="btn btn-sm mb-0" type="button">Активен</button>
                        <button @click="editOfferData.active = 2" :class="editOfferData.active > 1 ? 'btn-primary' : 'btn-outline-primary'" class="btn btn-sm mb-0" type="button">Приват</button>
                        <button @click="renameDomain()" class="btn btn-outline-primary btn-sm mb-0" type="button">Сменить домен офера</button>
                      </div>

                      <br>
                      <h5 class="mt-0">Название офера</h5>
                      <input v-model="editOfferData.offerName" type="text" class="form-control" aria-label="With textarea" aria-describedby="offer_name">
                        <div class="media mb-3">
                          <img v-if="editOfferData.offerAvatar" :src="editOfferData.offerAvatar" class="d-flex mr-3 rounded-circle" alt="...">
                          <div class="mb-3">
                            <label for="img" class="form-label">Выбор картинки</label>
                            <input @change="onFileChange" id="img" class="form-control form-control-sm" type="file">
                          </div>
                            <div class="media-body">
                              <div class="card-body">
                                <h5 class="mt-0">Домен офера</h5>
                                <input v-model="editOfferData.offerDomain" type="text" class="form-control" aria-label="With textarea" aria-describedby="offer_domain">
                                <h5 class="mt-0">Апселы:</h5>
                                <div v-for="(e, k) in offerUpsels.upsels" :key="k" class="container pt-1 pb-1 mb-3 z-depth-3 col s12">
                                  <div class="col s9">
                                    <div class="input-group">
                                      <span class="input-group-text" :id="`${k}title`">Заголовок цены {{ k + 1 }}.</span>
                                      <input v-model="e.title" type="text" class="form-control" aria-label="With textarea" :aria-describedby="`${k}title`">
                                    </div>
                                  </div>
                                  <div class="col s3">
                                    <div class="input-group">
                                      <span class="input-group-text" :id="`${k}amount`">Цена.</span>
                                      <input v-model="e.amount" type="number" step="1" class="form-control" aria-label="With textarea" :aria-describedby="`${k}amount`">
                                    </div>
                                  </div>
                                  <div class="col s12">
                                    <div class="input-group">
                                      <span class="input-group-text" :id="`${k}successUrl`">Ссылка после оплаты.</span>
                                      <input v-model="e.successUrl" type="text" class="form-control" aria-label="With textarea" :aria-describedby="`${k}successUrl`">
                                    </div>
                                  </div>
                                  <div class="col s12">
                                    <div class="input-group">
                                      <span class="input-group-text" :id="`${k}failUrl`">Ссылка после оплаты.</span>
                                      <input  v-model="e.failUrl" type="text" class="form-control" aria-label="With textarea" :aria-describedby="`${k}failUrl`">
                                    </div>
                                  </div>
                                  <button @click="offerUpsels.upsels.splice(k, 1)" :disabled="disabled" class="btn btn-secondary btn-sm">Убрать</button>
                                </div>
                                <button @click="offerUpsels.upsels.push({title: '', amount: 0, successUrl: '', failUrl: ''})" :disabled="disabled" class="btn btn-secondary btn-sm">Добавить</button>
                            </div>
                        </div>
                    </div> <!-- end card-body-->
                </div> <!-- end card-->
            </div> <!-- end col -->
        </div>

      </div> <!-- container-fluid -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'CreateOffers',
  data () {
    return {
      disabled: false,
      updateOffer: {},
      active: false,
      editOfferData: {
        active: 0
      },
      offerUpsels: {
        upsels: []
      }
    }
  },
  // props: {
  //   offer: Object
  // },
  components: {
  },
  created () {
    this.checkActive()
  },
  mounted () {
    // this.editOfferData = this.offer
    // this.offerUpsels = JSON.parse(this.editOfferData.offerUpsell)
  },
  // watch: {
  //   offer: function (val) {
  //     this.editOfferData = this.offer
  //   }
  // },
  methods: {
    renameDomain () {
      const newDomain = prompt('Укажи новый домен', 'https://')
      const newUps = []
      this.offerUpsels.upsels.forEach(element => {
        element.successUrl = element.successUrl.replace(this.editOfferData.offerDomain, newDomain)
        element.failUrl = element.failUrl.replace(this.editOfferData.offerDomain, newDomain)
        newUps.push(element)
      })
      setTimeout(() => {
        this.editOfferData.offerDomain = newDomain
        this.offerUpsels.upsels = newUps
      }, 100)
    },
    checkActive () {
      if (this.editOfferData.active === 1) {
        this.editOfferData = true
      }
    },
    saveOffer () {
      this.disabled = true
      this.editOfferData.offerUpsell = JSON.stringify(this.offerUpsels)
      this.$emit('newOfferData', this.editOfferData)
    },
    onFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.createImage(files[0])
    },
    createImage (FileObject) {
      const reader = new FileReader()
      reader.onload = (event) => {
        this.editOfferData.offerAvatar = event.target.result
      }
      reader.readAsDataURL(FileObject)
    }
  }
}
</script>
