<template>
  <!-- Begin page -->
    <div v-if="loading" class="loading-overlay">
        <div class="loading-text">Загрузка...</div>
    </div>
    <div class="main-content">
        <HeaderTemplate :user="user" :dashName="dashName" :startApps="startApps"/>
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">
      <UpdateOffers v-if="step === 'update'" :offer="editOfferData" @newOfferData="saveUpdateOfferData" />
      <CreateOffer v-if="step === 'create'" @newOfferData="saveNewOfferData" />
        <div v-if="step === 'list'" class="page-content">
            <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">Сайты</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item"><a href="javascript: void(0);">{{ dashName }}</a></li>
                            <li class="breadcrumb-item active">Сайты</li>
                        </ol>
                    </div>

                </div>
                    <button @click="createOffer()" type="button" class="btn btn-warning btn-sm waves-effect waves-light">Создать</button>
            </div>
        </div>
        <!-- end page title -->

        <div class="row" v-for="(val, key) in offersRowList" :key="key">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                      <small :class="val.active < 1 ? 'text-warning' : val.active > 1 ? 'text-secondary' : 'text-success'">{{val.active < 1 ? 'Отключен' : val.active > 1 ? 'Приват' : 'Активен'}}</small>
                        <h4 class="card-title">{{val.id}}. {{val.offerName}}</h4>
                        <small>Обновлен: {{ val.updatedAt }}</small>
                        <p class="card-subtitle mb-4">Разместил userId-{{val.userId}}</p>
                        <div class="media mb-3">
                            <img class="d-flex mr-3 rounded-circle" :src="val.offerAvatar" :alt="val.offerName" height="68">
                            <div class="media-body">
                                <h5 class="mt-0">Ссылка для привлечения</h5>
                                <div class="form-group">
                                    <label for="simpleinput">Для использования ссылки, необходимо рандомизировать ее конец, заменив {RANDOM} на любую строку</label>
                                    <input type="text" readonly id="simpleinput" class="form-control" placeholder="ссылка" :value="publicDomain + '/pub/' + val.id + '/' + userId + '/{RANDOM}'">
                                </div>
                                <div class="form-group">
                                  <button @click="editOffer(val)" type="button" class="btn btn-warning btn-sm waves-effect waves-light sa-long-content">Редактировать</button>
                                  <button @click="showAlertKey(key, val.id)" type="button" class="btn btn-success btn-sm waves-effect waves-light sa-long-content">Ссылки для установки</button>
                                  <button @click="showAlert(key, val.id)" type="button" class="btn btn-info btn-sm waves-effect waves-light sa-long-content">Апселлы</button>
                                </div>
                            </div>
                        </div>
                    </div> <!-- end card-body-->
                </div> <!-- end card-->
            </div> <!-- end col -->
        </div>

            </div> <!-- container-fluid -->
        </div>
        <!-- End Page-content -->

        <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        2020 © Xeloro.
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right d-none d-sm-block">
                            Design & Develop by Myra
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
    <!-- end main content-->

</div>
<!-- END layout-wrapper -->

<!-- Overlay-->
<div class="menu-overlay"></div>
</template>

<script>

import wsMixin from '../mixins/websocket.js'
import HeaderTemplate from '@/components/HeaderTemplate.vue'
import Swal from 'sweetalert2'
import UpdateOffers from '@/components/UpdateOffers.vue'
import CreateOffer from '@/components/CreateOffer.vue'
// import md5 from 'md5'

export default {
  name: 'ServiceOffers',
  mixins: [wsMixin],
  components: {
    HeaderTemplate, UpdateOffers, CreateOffer
  },
  data () {
    return {
      startApps: true,
      loading: true,
      onMessage: null,
      user: {},
      userId: localStorage.getItem('uid'),
      userSid: localStorage.getItem('sid'),
      allOffers: [],
      offersRowList: [],
      publicDomain: '',
      startRow: 0,
      rowsPerPage: 10,
      createScript: null,
      orderBy: '',
      wsParam: { orderBy: false, where: false, ascDesc: false, limit: false, offset: false },
      checkConnected: true,
      buttonTextArray: ['Круто!', 'Хера-се)!', 'Кайф!', 'Ну все, закрой.', 'ЗБС!', 'Шик))', 'Ага, понятно.', 'Норм.', 'Ок ок.', 'Сойдет.', 'Все понял.', 'Нормально так.'],
      buttonText: '',
      step: 'list',
      editOfferData: {}
    }
  },
  created () {
    this.connectNew()
  },
  mounted () {
    // const insertScript = document.createElement('script')
    // insertScript.setAttribute('src', 'assets/js/includes/home.js')
    // document.body.appendChild(insertScript)
    setTimeout(() => {
      this.sendApi()
    }, 200)
  },
  beforeUnmount () {
    this.checkConnected = false
    this.connection.close()
  },
  watch: {
    wsParam: function (val) {
      this.sendApi()
    }
  },
  methods: {
    showAlert (key, offerId) {
      this.buttonText = this.buttonTextArray[Math.floor(Math.random() * this.buttonTextArray.length)]
      const upsellData = JSON.parse(this.offersRowList[key].offerUpsell).upsels
      let tr = ''
      upsellData.forEach((el, upsId) => {
        tr += '<tr><th scope="row"><a href="' + this.payLink(offerId, upsId) + '" target="_blank">' + upsId + '. ' + el.title + '</a></th><th>$' + el.amount + '</th><th>$' + el.amount_user + '</th><th>$' + el.amount_admin + '</th></tr>'
      })
      Swal.fire({
        title: '<i>Список цен</i>',
        type: 'info',
        html: '<div class="table-responsive"><table class="table table-hover mb-0"><thead><tr><th>#</th><th>Вся цена</th><th>Юзер цена</th><th>Админ цена</th></tr></thead>' +
        '<tbody>' + tr + '</tbody></table></div>',
        showCloseButton: true,
        confirmButtonClass: 'btn btn-confirm mt-2',
        confirmButtonText: '<i class="mdi mdi-thumb-up-outline"></i> ' + this.buttonText
      })
    },
    showAlertKey (key, offerId) {
      this.buttonText = this.buttonTextArray[Math.floor(Math.random() * this.buttonTextArray.length)]
      const upsellData = JSON.parse(this.offersRowList[key].offerUpsell).upsels
      let tr = ''
      upsellData.forEach((el, upsId) => {
        const url = this.payLink(offerId, upsId)
        tr += '<tr><th scope="row"><a href="' + url + '" target="_blank">' + upsId + '. ' + el.title + '</a></th><th><input type="text" value="' + url + '" readonly></th><th>$' + el.amount + '</th></tr>'
      })
      Swal.fire({
        title: '<i>Ссылки для установки</i>',
        type: 'info',
        html: '<div class="table-responsive"><table class="table table-hover mb-0"><thead><tr><th>#</th><th>Ссылка</th><th>Вся цена</th></tr></thead>' +
        '<tbody>' + tr + '</tbody></table></div>',
        showCloseButton: true,
        confirmButtonClass: 'btn btn-confirm mt-2',
        confirmButtonText: '<i class="mdi mdi-thumb-up-outline"></i> ' + this.buttonText
      })
    },
    editOffer (data) {
      this.editOfferData = data
      this.step = 'update'
    },
    createOffer () {
      this.step = 'create'
    },
    saveNewOfferData (data) {
      this.step = 'list'
      this.sendMessage(
        {
          event: '/createOffer',
          userId: this.userId,
          sid: this.userSid,
          param: JSON.stringify(data)
        }
      )
    },
    saveUpdateOfferData (data) {
      this.step = 'list'
      this.sendMessage(
        {
          event: '/editOffer',
          userId: this.userId,
          sid: this.userSid,
          param: JSON.stringify(data)
        }
      )
    },
    payLink (offerId, upselId, domain = true) {
      const link = btoa(btoa(this.linkKey) + btoa(JSON.stringify({ o: offerId, ups: upselId })))
      if (domain) {
        return this.publicDomain + '/pay/' + link
      } else {
        return '/pay/' + link
      }
    },
    sendApi () {
      this.sendMessage({
        event: '/serviceOffers',
        param: JSON.stringify(this.wsParam),
        userId: this.userId,
        sid: this.userSid
      })
    },
    movePages (index) {
      const newStartRow = this.startRow + index
      if (newStartRow >= 0) {
        this.startRow = newStartRow
      }
      const offset = this.startRow > 0 ? this.startRow * this.rowsPerPage : 0
      this.wsParam.limit = this.rowsPerPage
      this.wsParam.offset = offset
      this.sendApi()
    },
    connectNew () {
      console.log('Starting connection to WebSocket Server')
      this.urlConnect = (localStorage.getItem('sid') && localStorage.getItem('qid')) ? this.wsConnect + '/?sid=' + localStorage.getItem('sid') + '&qid=' + localStorage.getItem('qid') + this.rndGid : this.wsConnect + '/?sid=' + this.sid
      this.connection = new WebSocket(this.urlConnect)

      this.connection.onopen = (event) => {
        this.connectFlag = true
        console.log('Successfully connected to the echo websocket server...')
      }

      this.connection.onmessage = (event) => {
        this.onMessage = JSON.parse(event.data)
        if (this.onMessage.error) {
          localStorage.clear()
          location.href = '/'
        }
        switch (this.onMessage.event) {
          case 'api':
            this.sendApi()
            break
          case '/serviceOffers':
            this.user = JSON.parse(this.onMessage.userData)
            this.allOffers = this.onMessage.offersList
            this.offersRowList = this.onMessage.offersList
            this.publicDomain = this.onMessage.publicDomain
            // .slice(this.startRow, this.rowsPerPage + this.startRow)
            if (this.loading) {
              this.loading = false
            }
            setTimeout(() => {
              this.startApps = false
            }, 1000)
            break
          case '/editOffer':
            if (this.onMessage.error !== true) {
              alert('Оффер обновлен')
            }
            break
          case '/createOffer':
            if (this.onMessage.error !== true) {
              alert('Оффер добавлен')
              location.reload()
            }
            break
          default:
            break
        }
      }

      this.connection.onclose = (e) => {
        if (this.checkConnected) {
          console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
          this.connectNew()
        } else {
          console.log('Socket is closed. beforeUnmount')
        }
      }
    },
    sendMessage (message) {
      if (!this.connection.readyState) {
        setTimeout(() => {
          this.sendMessage(message)
        }, 1000)
      } else {
        this.connection.send(JSON.stringify(message))
      }
    }
  }
}
</script>
