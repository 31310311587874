<template>
  <div>
      <div class="container">
          <div class="row">
              <div class="col-12">
                  <div class="d-flex align-items-center min-vh-100">
                      <div class="w-100 d-block bg-white shadow-lg rounded my-5">
                          <div class="row">
                              <div class="col-lg-5 d-none d-lg-block bg-login rounded-left"></div>
                              <div class="col-lg-7">
                                  <div class="p-5">
                                      <div class="text-center mb-5">
                                          <a class="text-dark font-size-22 font-family-secondary">
                                              <i class="mdi mdi-alpha-x-circle"></i> <b>Регистрация</b>
                                          </a>
                                      </div>
                                      <p class="text-muted mb-4">Укажи логин, телеграм и пароль для входа.</p>
                                      <p :class="{'text-success': !error, 'text-danger': error}">{{ message }}</p>
                                      <form class="user">
                                          <div class="form-group">
                                              <input v-model="login" type="text" class="form-control form-control-user" id="exampleInputLogin" placeholder="Login">
                                          </div>
                                          <div class="form-group">
                                              <input v-model="telegram" type="text" class="form-control form-control-user" id="exampleInputLogin" placeholder="Telegram">
                                          </div>
                                          <div class="form-group">
                                              <input v-model="password" type="text" class="form-control form-control-user" id="exampleInputPassword" placeholder="Password">
                                          </div>
                                          <button @click.prevent="registerUser()" :disabled="disabled" class="btn btn-success btn-block waves-effect waves-light"> Регистрация </button>

                                      </form>

                                      <div class="row mt-4">
                                          <div class="col-12 text-center">
                                              <p class="text-muted mb-0">Есть аккаунт? <a href="/login" class="text-muted font-weight-medium ml-1"><b>Вход</b></a></p>
                                          </div> <!-- end col -->
                                      </div>
                                      <!-- end row -->
                                  </div> <!-- end .padding-5 -->
                              </div> <!-- end col -->
                          </div> <!-- end row -->
                      </div> <!-- end .w-100 -->
                  </div> <!-- end .d-flex -->
              </div> <!-- end col-->
          </div> <!-- end row -->
      </div>
      <!-- end container -->
  </div>
</template>

<script>
import wsMixin from '../mixins/websocket.js'
// import md5 from 'md5'

export default {
  name: 'RegisterView',
  mixins: [wsMixin],
  data () {
    return {
      sid: localStorage.getItem('qid') ? localStorage.getItem('qid') : Date.now(),
      login: '',
      telegram: '',
      password: '',
      disabled: true,
      error: false,
      message: '',
      onMessage: null
    }
  },
  created () {
    localStorage.getItem('qid') ? localStorage.getItem('qid') : localStorage.setItem('qid', this.sid)
    this.connectNew()
  },
  mounted () {
    // dfgdgf
  },
  watch: {
    login: function (val) {
      if (this.login.length < 4 || this.password.length < 8 || this.telegram.length < 3) {
        this.disabled = true
      } else {
        this.disabled = false
      }
    },
    telegram: function (val) {
      if (this.login.length < 4 || this.password.length < 8 || this.telegram.length < 3) {
        this.disabled = true
      } else {
        this.disabled = false
      }
    },
    password: function (val) {
      if (this.login.length < 4 || this.password.length < 8 || this.telegram.length < 3) {
        this.disabled = true
      } else {
        this.disabled = false
      }
    },
    onMessage: function (val) {
      if (this.onMessage.event === 'onRegister') {
        if (this.onMessage.error) {
          this.error = true
          this.message = this.onMessage.message
        } else {
          this.error = false
          this.message = 'Успешная регистрация. Дождись активации аккаунта.'
          setTimeout(() => {
            this.apiHooks()
          }, 3000)
        }
      }
    }
  },
  beforeUnmount () {
    // this.checkConnected = false
    // this.connection.close()
  },
  methods: {
    registerUser () {
      // this.disabled = true
      this.sendMessage({ event: 'register', userlogin: this.login, usertelegram: this.telegram, userpassword: this.password })
      // localStorage.setItem('uid', 'abdhgrh')
      // this.$router.push({ name: 'home' })
    },
    sendMessage (message) {
      if (!this.connection.readyState) {
        setTimeout(() => {
          this.sendMessage(message)
        }, 1000)
      } else {
        this.connection.send(JSON.stringify(message))
      }
    },
    connectNew () {
      console.log('Starting connection to WebSocket Server')
      this.urlConnect = (localStorage.getItem('sid') && localStorage.getItem('qid')) ? this.wsConnect + '/?sid=' + localStorage.getItem('sid') + '&qid=' + localStorage.getItem('qid') + this.rndGid : this.wsConnect + '/?sid=' + this.sid
      this.connection = new WebSocket(this.urlConnect)

      this.connection.onopen = (event) => {
        // console.log(event)
        this.connectFlag = true
        console.log('Successfully connected to the echo websocket server...')
      }

      this.connection.onmessage = (event) => {
        this.onMessage = JSON.parse(event.data)
      }

      this.connection.onclose = (e) => {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
        this.connectNew()
      }
    },
    apiHooks () {
      // alert(JSON.stringify(this.onMessage))
      // this.$router.push({ name: 'home' })
      window.location.href = '/'
    }
  }
}
</script>
