const wsMixin = {
  data () {
    return {
      dashName: 'free-ppdash', // '65-reg', // 'buzz-dash',
      wsConnect: 'wss://ws.api-platform.site/wss', // 'wss://ws.cloud-bin-pay.pro/wss', // 'wss://os.api-platform.site/wss', wss://ws.api-platform.site/wss?sid=1724002435102
      apiConnect: 'https://ws.cloud-bin-pay.pro/apiConnect.php', // 'https://ws.cloud-bin-pay.pro/apiConnect.php', // 'https://os.api-platform.site/apiConnect.php',
      sid: localStorage.getItem('qid') ? localStorage.getItem('qid') : Date.now(),
      connection: null,
      urlConnect: '',
      rndGid: Math.floor(Math.random() * (9999999 - 100000) + 100000),
      wsSend: null,
      connectFlag: false,
      linkKey: 'Zhjc11102006'
      // onMessage: null
    }
  },
  created () {
    // this.connectNew()
  },
  methods: {
    // connectNew () {
    //   console.log('Starting connection to WebSocket Server')
    //   this.urlConnect = (localStorage.getItem('sid') && localStorage.getItem('qid')) ? this.wsConnect + '/?sid=' + localStorage.getItem('sid') + '&qid=' + localStorage.getItem('qid') : this.wsConnect + '/?sid=' + this.sid
    //   this.connection = new WebSocket(this.urlConnect)

    //   this.connection.onopen = (event) => {
    //     // console.log(event)
    //     this.connectFlag = true
    //     console.log('Successfully connected to the echo websocket server...')
    //   }

    //   this.connection.onclose = (e) => {
    //     console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
    //     this.connectNew()
    //   }
    // },
    // sendMessage (message) {
    //   if (!this.connection.readyState) {
    //     setTimeout(() => {
    //       this.sendMessage(message)
    //     }, 1000)
    //   } else {
    //     this.connection.send(JSON.stringify(message))
    //   }
    // }
  }
}

export default wsMixin
